.section-section h1 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  color: #262626;
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
  .section-section {
    margin-bottom: 20px;
  }
  .section-banner {
    margin-bottom: 20px;
  }
}
@media (min-width: 767.98px) {
  .section-section {
    margin-bottom: 30px;
  }
  .section-banner {
    margin-bottom: 30px;
  }
}
@media (min-width: 991.98px) {
  .section-section {
    margin-bottom: 50px;
  }
  .section-banner {
    margin-bottom: 50px;
  }
}

