#subCategory {
  .subList {
    .item {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          max-width: 210px;
        }
        h1 {
          font-size: 23px;
          line-height: 29px;
          font-weight: 500;
        }
      }
    }
  }
}