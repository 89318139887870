#brandsList {
  .title {
    h1 {
      font-size: 20px;
      line-height: 25px;
      font-weight: 700;
      color: #262626;
      margin-bottom: 20px;
    }
  }
}

.swiper-slide {
  margin-top: 0 !important;
  padding: 0 !important;
}

/* Additional customization */
.brands {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* Adjust this to suit your layout */
  width: 100%;
  height: 100%;
  a {
    transition: ease-in-out .35s;
    display: block;
    overflow: hidden;
    img {
      transition: ease-in-out .35s;
      transform: scale(1);
    }
    &:hover {
      transition: ease-in-out .35s;
      img {
        transition: ease-in-out .35s;
        transform: scale(1.1);
      }
    }
  }
}

.brands img {
  max-width: 100%;
  height: auto;
  display: block;
}

.swiper-grid-column {
  flex-direction: row !important;
}
.swiper-grid > .swiper-wrapper {
  flex-wrap: wrap;
}
.swiper-grid-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: row;
  grid-row-gap: 30px;
}


// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  #brandsList {
    margin-bottom: 50px;
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {
  #brandsList {
    margin-bottom: 50px;
  }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
  #brandsList {
    margin-bottom: 50px;
  }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}