/* Home Slider */
.home-slider {

  .home-next, .home-prev {
    background-color: rgba(255, 255, 255, 0.5);
    transition: ease-in-out .25s;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    &:hover,
    &:focus {
      transition: ease-in-out .25s;
      background: rgba(255, 255, 255, .8);
    }
    i {
      color: #3498db;
    }
  }

  .home-prev {
    left: 10px;
  }

  .home-next {
    right: 10px;
  }
}

/* Banner Slider */
.banner-slider {
  margin-bottom: 55px;
  .banner-next, .banner-prev {
    background-color: rgba(255, 255, 255, 0.5);
    transition: ease-in-out .25s;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    &:hover,
    &:focus {
      transition: ease-in-out .25s;
      background: rgba(255, 255, 255, .8);
    }
    i {
      color: #3498db;
    }
  }

  .banner-prev {
    left: 10px;
  }

  .banner-next {
    right: 10px;
  }
}




// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .home-next, .home-prev {
    width: 30px;
    height: 30px;
  }
  .banner-next, .banner-prev {
    width: 30px;
    height: 30px;
  }
  .home-slider,
  .banner-slider {
    margin-bottom: 20px;
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {
  .home-slider,
  .banner-slider {
    margin-bottom: 30px;
  }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
  .home-slider,
  .banner-slider {
    margin-bottom: 50px;
  }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}