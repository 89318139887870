.section-section {
  h1 {
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    color: #262626;
    margin-bottom: 20px;
  }
}


// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .section-section {
    margin-bottom: 20px;
  }
  .section-banner {
    margin-bottom: 20px;
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {
  .section-section {
    margin-bottom: 30px;
  }
  .section-banner {
    margin-bottom: 30px;
  }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
  .section-section {
    margin-bottom: 50px;
  }
  .section-banner {
    margin-bottom: 50px;
  }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}