.footer {
  background-color: #ffffff;
  padding: 40px 20px;
  color: #333;
  font-family: Arial, sans-serif;
}

.footer-cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  grid-gap: 40px;
}

.footer-col {
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
}

.footer-col h4 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
}

.footer-col a {
  display: block;
  color: #333;
  text-decoration: none;
  margin-bottom: 8px;
  font-size: 14px;
}

.footer-col a:hover {
  color: #007bff;
}

.footer-sub-cols {
  display: flex;
  justify-content: space-between;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a {
  color: #333;
  font-size: 18px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #007bff;
}

.footer-copyright {
  background-color: #f7f7fb;
  padding: 15px 0;
  text-align: center;
  font-size: 14px;
  color: #777;
}
