header {
  &.default-header,
  &.mobile-header {
    .d-logo {
      max-width: 150px;
    }
  }
}


// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .default-header {
    display: none;
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {
  .default-header {
    display: none;
  }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
  .default-header {
    display: block;
  }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {
  .default-header {
    display: block;
  }
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}