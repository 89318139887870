.mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hamburger-btn, .cart-btn, .search-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.logo img {
    height: 40px;
}

.search-input {
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    margin-right: 10px;
    flex-grow: 1;
}


// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .mobile-header {
        display: flex;
    }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {
    .mobile-header {
        display: flex;
    }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
    .mobile-header {
        display: none;
    }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {
    .mobile-header {
        display: none;
    }
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}