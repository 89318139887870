#categoriesList {

  .categories-list {
    .category {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        grid-gap: 10px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        text-align: center;
        align-self: center;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }
    }
  }
}


// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  #categoriesList {
    margin-bottom: 20px;
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {
  #categoriesList {
    margin-bottom: 30px;
  }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
  #categoriesList {
    margin-bottom: 50px;
  }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}